const ENUM = {

  RESULT_NOT_FOUND: 'não foram encontrados resultados',
  TRANSACAO_APROVACAO_KEY: 'aprovacao',

  // CARDLOCK
  OPERACAO_ALTERACAO_STATUS_CARTAO: 'alteracaoStatusCartao',
  STATUS_BLOQUEADO: 'bloqueado',
  STATUS_CANCELADO: 'cancelado',
  ERROR_BLOQUEAR_CARTAO: 'Erro ao bloquear o cartão, por favor tente mais tarde',
  ALT_CARD_LOCK: 'CardLock',
  TITLE_BLOQUEAR_CARTAO: 'bloquear cartão',
  MSG_CONFIRMAR_BLOQUEAR_CARTAO: 'Deseja mesmo bloquear o seu cartão? Você não poderá usar o cartão ou suas funcões.',
  DANGER_TITLE_AGORA_NAO: 'agora não',
  TITLE_BLOQUEAR: 'bloquear',

  // CARDLOCKED
  ALT_CARTAO_BLOQUEADO: 'Icon_Cartão_Bloqueado',
  TITLE_BLOQUEIO_SUCESSO: 'bloqueio do cartão solicitado com sucesso!',
  MSG_CARTAO_BLOQUEADO: 'pode ser que você ainda visualize seu cartão como ativo. Mas não se preocupe, seu cartão será bloqueado em instantes.',
  BTN_TITLE_OK: 'ok',
  // CHANGEPASSWORD
  ERRO_TOKEN_INCORRETO: 'erro ao efetuar operação, verifique se o Token está correto.',
  ERRO_EFETUAR_OPERACAO: 'Erro ao efetuar operação!',
  ERRO_SENHA_DIFERENTE: 'Confirmação de senha inválida.',
  TITLE_ALTERAR_SENHA: 'Alterar senha',
  TITLE_ALTERAR_SENHA_CARTAO: 'Altere a senha do seu cartão',
  PLACEHOLDER_CADASTRE_SENHA: 'cadastre a senha',
  PLACEHOLDER_CONFIRME_SENHA: 'confirme a senha',
  TITLE_ALTERAR: 'alterar',

  // CLIENTE
  TITLE_CLIENTE_PROJETO: 'Cliente ou projeto',
  PLACEHOLDER_CLIENTE_PROJETO: 'procure por um cliente ou projeto',

  // CONFIRMPHONE
  MSG_PIN_SUCESSO: 'senha do cartão alterado com sucesso',
  MSG_TOKEN_INVALIDO: 'token inválido',
  BTN_TITLE_ENVIAR_NOVAMENTE: 'enviar novamente',
  MSG_SOLICITAR_ENVIO: 'você pode soliciar o envio de um novo código em',
  MSG_SEGUNDOS: 'segundos',
  BTN_TITLE_CONFIRMAR: 'confirmar',
  OPERACAO_ALTERACAO_PIN: 'alteracaoPINCartao',
  TITLE_ENVIO_CODIGO: 'Envio de Código',
  TITLE_CONFIRM_TELEFONE: 'Confirme o telefone',
  MSG_DIGITE_CODIGO: 'Digite o código que foi enviado para o número',

  // DESPESA
  TITLE_TIPO_DESPESA: 'Tipo de despesa',
  PLACEHOLDER_PROCURE_TIPO: 'procure um tipo',

  // ERRORSCREEN
  // ERRO_EFETUAR_OPERACAO
  ERROR_SMS_LINK_PRESTACAO_CONTA: 'Erro na edição da Prestação de Conta',
  MSG_TENTE_MAIS_TARDE: 'Desculpe, um erro inesperado ocorreu, por favor tente novamente mais tarde.',
  BTN_TITLE_OK_ENTENDI: 'ok, entendi',

  // EXTRACTFILTER
  ERROR_DATA_INICIO_FIM: 'data inicial e data final inválidas.',
  ERROR_DATA_INICIO: 'data inicial inválida.',
  ERROR_DATA_FIM: 'data final inválida.',
  ERROR_MAX_80_DIAS: 'o intervalo máximo deve ser de 80 dias.',
  ERROR_DATA_INICIO_MAIOR_FIM: 'a data final deve ser maior que a inicial.',
  TITLE_FILTRAR_PERIODO: 'filtrar por período',
  MSG_USE_80_DIAS: 'Utilize um intervalo de até 80 dias',
  PLACEHOLDER_DATA_INICIO: 'data início',
  PLACEHOLDER_DATA_FIM: 'data fim',
  BTN_DANGER_TITLE_VOLTAR: 'voltar',
  BTN_TITLE_PESQUISAR: 'pesquisar',

  // FINGERPRINT
  ALT_FINGERPRINT: 'Fingerprint',
  TITTLE_ACESSO_DIGITAL: 'acesso com a digital',
  MSG_LIBERAR_DIGITAL: 'deseja liberar o desbloqueio da sua conta usando a digital?',
  // DANGER_TITLE_AGORA_NAO: 'agora não',
  TITLE_LIBERAR: 'liberar',

  // HELP
  TITLE_CANAIS_AJUDA: 'Canais de ajuda',
  TITLE_ENTRE_EM_CONTATO: 'Entre em contato',
  PLACEHOLDER_QUAL_DUVIDA: 'qual é a sua duvida?',
  COLLAPSE_ATIVAR_CARTAO: 'Como consigo ativar meu cartão?',

  ALT_ALTERAR_SENHA: 'alterar senha',
  ALT_ALTERAR_SENHA_EX: 'alterar senha exemplo',

  ANSWER_ATIVAR_CARTAO: `Para ativar o seu cartão, basta seguir os passos abaixo. É rápido e fácil. Como deve ser.
  
  1 - Acesse card.bpp.com.br.
  
  2 - Informe o número ou o ID do cartão (o ID está localizado na parte de trás do seu cartão) e a sua data de nascimento.
  
  3 - Informe seu celular com DDD.
  
  4 - Crie a senha do cartão com 4 dígitos numéricos.
  
  5 - Informe o token (6 dígitos numéricos) que foi enviado via SMS para o seu número de celular (o mesmo número de celular que foi cadastrado no passo 3).
  
  6 - Pronto! Seu cartão foi ativado.`,
  COLLAPSE_ACESSO_BPP: `Como acesso meu BPP Card?`,
  ANSWER_ACESSO_BPP: `Após baixar o BPP Card  acesse utilizando o número do seu cartão ou ID (localizado na parte de trás do cartão) e sua data de nascimento.`,
  COLLAPSE_CONSULTAR_SALDO: 'Como consulto meu saldo e extrato no BPP Card?',
  ANSWER_CONSULTAR_SALDO: 'É só acessar o aplicativo BPP Card com o número do seu cartão e data de nascimento, e pronto, o saldo e extrato estarão disponíveis para a sua consulta.',
  COLLAPSE_PRESTAR_CONTA: 'Como faço para realizar prestação de contas?',
  ANSWER_PRESTAR_CONTA: ` 1 - Selecione uma operação em que deseja prestar contas no seu extrato.
  
  2 - Confirme os dados solicitados e clique em "prestar conta"
  Pronto, sua prestação de conta já foi encaminhada!`,
  COLLAPSE_CRIAR_CONTA: 'Como criar uma conta?',
  COLLAPSE_VERIFICA_SALDO: 'Onde verifico meu saldo?',
  RESP_SALDO: 'Seu saldo estará sempre disponível na tela principal do seu aplicativo, logo a baixo das informações da sua conta. Caso um retangulo cinza esteja aparecendo, aperte o ícone de olho',
  RESP_SALDO_CONTINUA: 'ao lado do retangulo, e seu saldo aparecerá!',
  BTN_TITLE_CHAT: 'chat',
  BTN_TITLE_EMAIL: 'e-mail',
  BTN_TITLE_CONTATO: 'contato',
  ALT_ICONE_OLHO: 'Eye Icon',

  // perguntas e respostar atualizadas
  COLLAPSE_CARD_BLOCK: `Meu cartão está bloqueado, e agora?`,
  ANSWER_CARD_BLOCK: `Entre em contato com a sua empresa para solicitar o desbloqueio do seu cartão!`,
  COLLAPSE_CHANGE_PASS: `Como altero minha senha?`,
  ANSWER_CHANGE_PASS_1: `Dentro do BPP Card selecione o botão "alterar senha":`,
  ANSWER_CHANGE_PASS_2: `E inclua sua nova senha:`,
  COLLAPSE_PASS_CHANGED: `Alterei minha senha e não consigo usar o cartão, o que faço?`,
  ANSWER_PASS_CHANGED: `Se você errou a senha três vezes, por motivos de segurança o seu cartão ficará bloqueado por 24hs. 

  Após este período você pode realizar uma nova alteração!

  Caso contrário, você pode ir direto em "alterar senha do cartão" para redefinir uma nova!`,
  COLLAPSE_CHARGE: `Como solicitar carga?`,
  ANSWER_CHARGE: `Você precisa entrar em contato com a sua empresa!`,
  COLLAPSE_SYMBOL: `O que significa os símbolos do extrato?`,
  ANSWER_SYMBOL_1: ` - Este símbolo significa que para aquele valor ainda não foi feito uma prestação de contas!`,
  ANSWER_SYMBOL_2: ` - Este símbolo significa que para aquele valor foi feito uma prestação de contas e ela está aprovada!`,
  ANSWER_SYMBOL_3: ` - Este símbolo significa que você recebeu uma carga no cartão!`,
  COLLAPSE_80_DAYS: `Posso consultar mais de 80 dias atrás no meu extrato?`,
  ANSWER_80_DAYS: `Pode sim, porém entre períodos de 80 dias. 

  Ex: 01/01/2017 até 01/03/2017.`,

  // HELP CHANNELS
  TITTLE_CONTACT: 'Entre em contato',
  TITTLE_ATENDIMENTO_PF: 'Atendimento à Pessoa Física: ',
  NUMBER_TELEFONE_PF: '4003-5301',
  MSG_CAPITAIS: 'Capitais ou regiões metropolitanas',
  NUMBER_TELEFONE_DEMAIS_PF: '0800-301-0301',
  MSG_DEMAIS_LOCALIDADES: 'Demais localidades',
  //   HORARIO_PF: 'De segunda à sexta das 8h às 22h',
  HORARIO_PF: 'Todos os dias das 8h às 22h',
  MSG_OUVIDORIA: 'Ouvidoria:',
  NUMERO_OUVIDORIA: '4003-3837',
  MSG_EXTERIOR: 'No exterior ligue a cobrar - Exceto celular',
  NUMERO_EXTERIOR: '+55 (11) 3588-4848',
  //  '(Capitais ou regiões metropolitanas)',
  NUMERO_OUVIDORIA_DEMAIS: '0800-880-0185',
  // ' '(Demais localidades)',
  EMAIL_OUVIDORIA: 'ouvidoria@bpp.com.br',
  HORARIO_OUVIDORIA: 'De segunda à sexta (exceto feriados) das 8h às 12h e das 13h às 17h',

  NUMBER_TELEFONE_FREE: '0800 880 0185',
  MSG_HORARIO_TELEFONE_FREE: 'Segunda à sexta das 8h às 12h e das 13h às 17h, exceto feriados.',
  NUMBER_TELEFONE: '4003 2712',

  MSG_TELEFONE: 'Capitais e regiões metropolitanas.',
  MSG_DUVIDAS: 'Dúvidas? Entre em contato',

  ALT_ICON_TEL: 'Ícone Telefone',

  // INICIO
  ERRO_DADOS_CARTAO: 'erro ao solicitar os dados do seu cartão',
  ERRO_DADOS_CONTA: 'erro ao solicitar os dados da sua conta',
  ERRO_DADOS_EXTRATO: 'ops, não foi possível carregar os extratos, puxe esta manssagem para baixo e solte para tentar novamente.',
  ERRO_DADOS_PESSOA: 'erro ao solicitar os dados pessoais',
  ERRO_ATIVACAO_CARTAO: 'erro ao solicitar os dados de ativação do cartão',
  ERRO_SEM_RESULTADO: 'não foram encontrados resultados',
  ERRO_SEM_RESULTADO_PERIODO: 'não foram encontrados resultados para o período',
  ALT_ICONE_EMPTY_SEARCH: 'icone_busca_vazia',

  ALT_BBP_LOGO: 'BPP Logo',

  EXTRACT_FILTER_PENDENTE: 'Pendente',
  EXTRACT_FILTER_RECUSADO: 'Recusado',
  EXTRACT_FILTER_TODOS: 'Todos',
  EXTRACT_FILTER_ENTRADA: 'Entrada',
  EXTRACT_FILTER_SAIDA: 'Saida',

  ITEM_NOME_CARGA: 'Carga',

  OPERATION_EXTRACT_LIST_TODOS_KEY: 'Todos',
  STATUS_CARTAO_PRONTO_ATIVACAO_KEY: 'prontoParaAtivacao',
  STATUS_CARTAO_EM_PROCESSAMENTO_KEY: 'emProcessamento',
  STATUS_CARTAO_ATIVO_KEY: 'ativo',
  TELEFONE_CELULAR_KEY: 'CELULAR',

  FOOTER_TEXT: 'Copyright © 2018 BPP. Todos os direitos reservados.',

  // ENTRADAS
  ITEM_NOME_CARGA_KEY: 'Carga',
  ITEM_NOME_TRANSFERENCIA_KEY: 'Transferência',
  ITEM_NOME_EXTORNO_KEY: 'Extorno',

  // SAIDAS
  ITEM_NOME_COMPRA_KEY: 'Compra',
  ITEM_NOME_DESCARGA_KEY: 'Descarga',
  ITEM_NOME_SAQUE_KEY: 'Saque',
  ITEM_NOME_TARIFA_SAQUE_KEY: 'Tarifa de Saque',

  ITEM_STATUS_RECUSADO_KEY: 'recusado',

  PRODUTO_PUBLICADO_ID_KEY_CORP: '5507f204-43c4-454a-b094-51c8d0d7d63d',
  PRUDUTOS_PUBLICADOS_IDS_KEYS_CORP: [
    '4982c42f-09ed-498f-a22b-8339d814b8b4',
    '5507f204-43c4-454a-b094-51c8d0d7d63d',
    '6a80399f-4a2a-4b71-b6cb-1d79fd4c1f0d',
    '83c7f1c1-9838-40ca-82ed-75ea855f3c06',
    '9494ba15-cc49-41dd-b530-d9634d40a90a',
    'be8b79bf-cc9e-4b94-839a-ebfe13cd22ec',
    'de9d29b3-8a13-4d54-8edf-fc043b22e622',
    'b8943808-c42c-11e9-aa8c-2a2ae2dbcce4'
  ],

  // LOGIN
  ERROR_TODOS_CAMPOS: 'todos os campos são obrigatórios.',
  ERROR_SERVICO_INDISPONIVEL: 'serviço indisponível, por favor tente novamente mais tarde.',
  TITLE_ACESSO_BPP_CARD: 'Acesse seu BPP Card',
  TITLE_SALVAR_ACESSO: 'salvar acesso',
  TITLE_ACESSAR: 'acessar',
  PLACEHOLDER_NUMBER_ID_CARTAO: 'número ou ID do cartão',
  PLACEHOLDER_DATA_NASCIMENTO: 'data de nascimento',

  // ALT_BBP_LOGO: 'BPP Logo',

  // MAKEACCOUNTABILITY
  ALT_FOTO_EXTRATO: 'prestar conta foto extrato',
  TITLE_TIPO_DESPESA_INDISPONIVEL: 'nenhum tipo de despesa disponivel',
  TITLE_TIPO_CLIENTE_INDISPONIVEL: 'nenhum tipo de cliente disponivel',
  TITLE_PRESTAR_CONTA: 'Prestar conta',
  ONE_PICTURE_OBRIGATORY: 'Selecione ao menos uma foto.',
  PLACEHOLDER_TIPO_DESPESA: 'tipo de despesa',
  PLACEHOLDER_TIPO_CLIENTE: 'cliente ou projeto',
  TEXT_FOTOS_COMPROVANTE: 'fotos dos comprovantes',
  PLACEHOLDER_OBSERVACOES: 'observações (opcional)',

  STATUS_LANCAMENTO_REPROVADO_KEY: 'reprovado',
  STATUS_LANCAMENTO_APROVADO_KEY: 'aprovado',
  STATUS_LANCAMENTO_PENDENTE_KEY: 'pendente',
  STATUS_LANCAMENTO_AGUARDANDO_APROVACAO_KEY: 'aguardandoAprovacao',

  // MENU
  STATUS_CARTAO_BLOQUEADO_KEY: 'bloqueado',
  TITLE_AJUDA: 'ajuda',
  // TITLE_BLOQUEAR_CARTAO: 'bloquear cartão',
  TITLE_ALTERAR_PIN: 'alterar senha',
  TITLE_SAIR: 'sair',

  // NOCONECTION
  MSG_VERIFIQUE_CONEXAO: 'Verifique a sua conexão com a internet e tente novamente. ',
  TITLE_ENTENDI: 'entendi',
  TITLE_SEM_INTERNET: 'sem internet',

  // PENDINGACTIVATION
  ALT_STATUS_ICON: 'status icon',
  TITLE_ATIVACAO_CARTAO_PENDENTE: 'Ativação em processamento, por favor aguarde',
  MSG_CADASTRO_FEITO: 'cadastro feito',
  MSG_AGUARDANDO_APROVACAO: 'aguardando aprovação',
  MSG_CARTAO_ATIVADO: 'cartão ativado',
  // BTN_TITLE_OK_ENTENDI: 'Ok, entendi',

  // REGISTERPASSWORD
  // ERRO_TOKEN_INCORRETO: 'Erro ao efetuar operação, verifique se o Token está correto.',
  // ERRO_EFETUAR_OPERACAO:
  // ERRO_SENHA_DIFERENTE: 'A Senha digitada não é a mesma confirmada.',
  // PLACEHOLDER_CADASTRE_SENHA: 'Cadastre a senha',
  // PLACEHOLDER_CONFIRME_SENHA: 'Confirme a senha',
  OPERACAO_ALTERAR_PIN_KEY: 'alteracaoPINCartao',
  TITLE_CADARTRA_PIN: 'Cadastrar Senha',
  TITLE_CADASTRA_SENHA: 'Cadastre a senha do seu cartão',
  TITLE_CADASTRA: 'cadastrar',

  // REGISTERPHONE
  ERRO_TELEFONE_DIFERENTE: 'o telefone digitado não é o mesmo confirmado.',
  ERRO_TELEFONE_INVALIDO: 'o número de celular inserido não é válido.',
  ERRO_TELEFONE: 'não foi possível cadastrar o número de telefone, por favor, entre em contato com nossa central.',
  TITLE_INFORME_TELEFONE: 'Informe seu telefone',
  MSG_MAX_DIGITOS_TELEFONE: 'O telefone deve possuir no máximo 9 digitos',
  PLACEHOLDER_INFORME_TELEFONE: 'informe o telefone',
  PLACEHOLDER_CONFIRM_TELEFONE: 'confirme o telefone',
  BTN_TITLE_CONTINUAR: 'continuar',
  DDI: '55',

  // SENDCODE
  ALT_HELP: 'Help',
  // TITLE_ENVIO_CODIGO: 'envio de código',
  MSG_ENVIO_NOVO_CODIGO: 'será enviado um novo código para o número',
  DANGER_TITLE_CANCELAR: 'cancelar',
  TITLE_ENVIAR: 'enviar',

  // SUCCESSPASSWORD
  ALT_CADEADO: 'Cadeado',
  TITLE_SENHA_DEFINIDA: 'Senha definida!',
  MSG_NOVA_SENHA_SUCESSO: 'Sua nova senha foi definida com sucesso!',
  BTN_TITLE_TELA_INICIO: 'ir para tela inicial',

  // UNLOCKCARD
  ALT_ICONE_CARTAO: 'Icon_Cartão',
  TITLE_DESBLOQUEAR_CARTAO: 'desbloquear cartão',
  MSG_DESEJA_DESBLOQUEAR_CARTAO: 'deseja mesmo desbloquear o seu cartão?',
  TITLE_DESBLOQUEAR: 'desbloquear',
  // DANGER_TITLE_AGORA_NAO

  // WELCOME
  ALT_ICONE_BEM_VINDO: 'Icon_Bem_Vindo',
  TITLE_BEM_VINDO: 'Bem-vindo!',
  MSG_CONFIGURAR_CARTAO: 'Antes de começarmos, precisamos configurar o seu cartão para sua segurança.',
  TITLE_VAMOS_LA: 'vamos lá!',

  // REMOVEIMAGE
  ALT_ICON_CLOSE: 'Icon_Close',
  ALT_ICONE_TRASH: 'Icon_Trash',
  ALT_SUA_IMAGEM: 'Sua Imagem',
  TITLE_REMOVER_IMG: 'excluir foto',
  MSG_REMOVER_IMG: 'Você tem certeza que deseja excluir a foto do comprovante para a prestação de contas?',
  BTN_DANGER_TITLE_AGORA_NAO: 'agora não',
  BTN_TITLE_REMOVER: 'excluir',

  // DIALOGCLOCKEDCARD
  MSG_SOLICITAR_DESBLOQUEIO: 'seu cartão está bloqueado e por este motivo você não pode prestar contas, entre em contato com a central de atendimentos para solicitar o desbloqueio.',
  BTN_TITLE_LIGAR: 'ligar',
  TITLE_CARTAO_BLOQUEADO: 'cartão bloqueado',

  // SUCCESSACCOUNTBILITY
  BTN_TITLE_SUCCESS_ACCOUNTABILITY: 'continuar',
  TITLE_SUCCESS_ACCOUNTABILITY: 'Prestação efetuada com sucesso!',
  MSG_NOVA_SUCCESS_ACCOUNTABILITY: 'Não mostrar mensagem novamente',

  // DETAILSOPERATION
  TITLE_DETALHES_OPERACAO: 'Detalhes da operação',

  // ---------------------------- COMPONENTES

  // BALANCE
  ALT_ICONE_ESCONDER_SALDO: 'esconder saldo',
  ALT_ICONE_CARTAO_PEQUENO: 'icone_cartaoPqn',
  ALT_ICONE_EDITAR: 'icone_editar',
  TITLE_OCULTO: 'oculto',
  TITLE_BLOQUEADO: 'bloqueado',
  TEXT_SALDO_CARTAO: 'saldo cartão',
  ERRO_NICK: 'erro ao alterar o apelido da sua conta',

  // STATUS_BLOQUEADO
  // CHANGENICK
  TITLE_APELIDO: 'Editar Apelido',
  PLACEHOLDER_NICK: 'Novo Apelido: Máximo 25 caracteres',

  // DANGERBOX
  ALT_ICONE_CARTAO_BLOQUEADO: 'cartao_bloqueado',

  // EXTRACTITEM
  // ITEM_NOME_CARGA: 'Carga',
  // ITEM_NOME_COMPRA_KEY
  // STATUS_LANCAMENTO_REPROVADO_KEY: 'recusado',
  // STATUS_LANCAMENTO_APROVADO_KEY: 'aprovado',
  // ENUM.STATUS_CARTAO_BLOQUEADO_KEY,
  // STATUS_LANCAMENTO_AGUARDANDO_APROVACAO_KEY: 'pendenteAprovacao',
  ANEXO_CONTRATUAL_ID_CARTAO_CORP: '491974de-7991-442b-89fa-a11953c2c1c1',
  // IMAGETOUCHABLE
  ALT_ICONE_LOGOUT: 'icone_Logout',

  // IMPUT
  ALT_ICONE_CHEVRON_RIGHT: 'Chevron Right',

  // NAVIGATION
  ALT_BACK: 'back',

  // RELEASECONTENT
  ALT_RELEASE: 'release',

  // ROWSWITCHABLE
  ALT_ILUSTRACAO: 'ilustração',

  // SEARCH
  ALT_BPP_LUPA: 'BPP Lupa',

  // SEARCHEXTRATO
  ALT_LUPA: 'lupa-icon',
  CAMPOS_OBRIGATORIOS: 'Todos os campos são obrigatórios',
  // DESKTOP ----------------------------

  // AJUDA
  ALT_VOLTAR_EXTRATO: 'voltar ao extrato',
  ALT_VOLTAR: 'voltar',

  // UpdateApp
  UPDATE_TITLE: 'Nova Versão Disponível',
  UPDATE_HOW_TO: 'Atualize seu APP, clicando no link da loja',
  UPDATE_EXPLAIN: 'Em alguns casos a nova versão pode demorar até 48h para estar disponível. Enquanto isso você pode usar a versão web ;)',
  ALT_ANDROID: 'Android Donwload',
  ALT_IOS: 'iOS Donwload',

  ALT_CHECKBOX: 'Check Box'

}

export default ENUM
