import React, { useState, useEffect } from 'react'
import './modal.css'

export default function Modal ({ isOpen, setOpen, children }) {
  const [needRender, setNeedRender] = useState(isOpen)
  let timeoutId

  useEffect(() => {
    isOpen && !needRender && setNeedRender(isOpen)
    if (!isOpen && needRender) {
      timeoutId = setTimeout(() => {
        setNeedRender(false)
      }, 800)
      return () => clearTimeout(timeoutId)
    }
  })

  if (!needRender) {
    return null
  }

  return (
    <div className='bpp-modal'>
      <div
        className={`bpp-modal-background animated ${isOpen ? 'fadeInModal' : 'fadeOutModal'} faster`}
      />
      <div
        className='bpp-modal-space'
        onClick={() => (setOpen ? setOpen(false) : null)}
      />
      <div
        className={`bpp-modal-content animated ${isOpen ? 'slideInUp' : 'slideOutDown'} faster`}
      >
        {children}
      </div>
    </div>
  )
}
