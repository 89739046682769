import React, { useState, useEffect, useCallback } from 'react'

import Main from '../../components/mobile/main/Main'
import { flex } from '../../infra/flex'
import Header from '../../components/mobile/header/Header'
import ENUM from '../../infra/constants'
import BPPLogo from '../../assets/full_logo_white.svg'
import { useSelector, useDispatch } from 'react-redux'
import {
  updateContaPaiId,
  updateContaId,
  updateIdPessoa,
  updatePortadorId
} from '../../store/modules/global/actions'
import { updateCardData } from '../../store/modules/card/actions'
import { carregaDadosCartaoApi } from '../../infra/api/inicioApi'
import { getPrestacaoContaHash } from '../../infra/api/MakeAccountabilityApi'
import Loading from '../../components/mobile/loading/Loading'
import bppFetch from '../../infra/bpp-fetch'
import { updateAccountData } from '../../store/modules/account/actions'

export default function HandleAccountabilityHash ({ ...props }) {
  const extractListCached = useSelector(state => state.extract.list)
  const extractFilteredListCached = useSelector(state => state.extract.listFiltered)
  const operationCached = useSelector(state => state.extract.operation)
  const accountData = useSelector(state => state.account.data)
  const cardData = useSelector(state => state.card.data)
  const cartaoIdData = useSelector(state => state.global.cartaoId)
  const accountId = useSelector(state => state.account.data && state.account.data.id)
  const rangeCached = useSelector(state => state.extract.range)
  const sinceDateCached = useSelector(state => state.extract.sinceDate)
  const untilDateCached = useSelector(state => state.extract.untilDate)
  const phone = useSelector(state => state.account.phone)
  const idPessoa = useSelector(state => state.global.idPessoa)

  const [hash, setHash] = useState('')

  useEffect(() => {
    let hashConst = ''
    if (props.location.state && props.location.state.hash && props.match.params.hash !== 'inicio') {
      hashConst = props.location.state.hash
      setHash(hashConst)
      startAccountability(hashConst)
    } else {
      hashConst = props.match.params.hash
      setHash(hashConst)
      callLogin(hashConst)
    }
  }, [])

  const dispatch = useDispatch()
  const saveContaId = useCallback(data => dispatch(updateContaId(data)), [])
  const saveCardData = useCallback(data => dispatch(updateCardData(data)), [
    cardData
  ])
  const savePortadoPessoaId = useCallback(
    data => dispatch(updatePortadorId(data)),
    []
  )

  const saveContaPaiId = useCallback(
    data => dispatch(updateContaPaiId(data)),
    []
  )
  const savePessoaId = useCallback(data => dispatch(updateIdPessoa(data)), [])

  const saveAccountData = useCallback(
    data => dispatch(updateAccountData(data)),
    [accountData]
  )

  const [isLoading, setLoading] = useState(true)

  function callLogin (hashConst) {
    props.history.push({
      pathname: '/',
      state: { hash: hashConst }
    })
  }

  function startAccountability (hashConst) {
    carregaDadosCartao(hashConst)
  }

  function carregaDadosCartao (hashConst) {
    return carregaDadosCartaoApi(cartaoIdData, props.isAppConnected).then(
      resultData => {
        if (resultData === 'error') {
          props.history.push({
            pathname: '/erro-generico',
            state: { title: ENUM.ERROR_SMS_LINK_PRESTACAO_CONTA, isLogout: true }
          })
        } else {
          saveCardData(resultData)
          saveContaId(resultData.contaId)
          savePortadoPessoaId(resultData.portadorPessoaId)
          if (resultData.status === ENUM.STATUS_CARTAO_PRONTO_ATIVACAO_KEY) {
            return Promise.all([
              carregaDadosConta(resultData.contaId, hashConst)
            ])
          } else {
            return Promise.all([
              carregaDadosConta(resultData.contaId, hashConst)
            ])
          }
        }
      }
    )
  }

  function carregaDadosConta (contaId, hashConst) {
    const options = {
      method: 'GET'
    }

    return bppFetch('/v1/conta/' + contaId, options, props.isAppConnected)
      .then(({ error, data, response, request }) => {
        if (error || data.erros) {
          props.history.push({
            pathname: '/erro-generico',
            state: { title: ENUM.ERROR_SMS_LINK_PRESTACAO_CONTA, isLogout: true }
          })
        } else {
          saveContaPaiId(data.contaPaiId)
          savePessoaId(data.pessoaTitularId)
          saveAccountData(data)
          setLoading(false)
          getPrestacaoContaHash(data.pessoaTitularId, hashConst, true).then(resultado => {
            let descricao = resultado.dadosExtrato.descricao
            resultado.descricao = descricao
            resultado.valor = resultado.dadosExtrato.valor
            resultado.nome = resultado.dadosExtrato.nome
            resultado.dataHora = resultado.dadosExtrato.dataHora
            props.history.push({ pathname: '/abrir-camera', state: { item: resultado } })
          })
        }
      })
      .catch(() =>
        props.history.push({
          pathname: '/erro-generico',
          state: { title: ENUM.ERROR_SMS_LINK_PRESTACAO_CONTA, isLogout: true }
        })
      )
  }

  function renderLoagind () {
    if (isLoading) {
      return <Loading style={{ alignSelf: 'center' }} />
    } else return null
  }

  const topContent = (
    <center>
      <img {...flex(0.1)} src={BPPLogo} alt={ENUM.ALT_BBP_LOGO} />
    </center>
  )

  return (
    <React.Fragment>
      <Header {...flex(0.1, { display: 'block' })} topContent={topContent} />
      <Main {...flex(0.9, { justifyContent: 'center' })}>
        {renderLoagind()}
      </Main>
    </React.Fragment >
  )
}
