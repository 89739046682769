import React from 'react'
import './input.css'
import ChevronRight from '../../../assets/chevron_right_solid.svg'
import { flex } from '../../../infra/flex'
import ENUM from '../../../infra/constants'

export default function Input ({ chevron, danger, greyBorder, style, ...props }) {
  if (danger) {
    return <input className='bpp-input-danger' style={{ width: '96%', ...style }} {...props} />
  }
  if (greyBorder) {
    return <input className='bpp-input-grey' style={{ width: '96%', ...style }} {...props} />
  }
  if (chevron) {
    return (

      <div {...flex(0)}>
        <input {...flex(0.9, style)} className='bpp-input-chevron' {...props} />
        <img {...flex(0.1)} src={ChevronRight} alt={ENUM.ALT_ICONE_CHEVRON_RIGHT} style={{ height: '14px', width: '8px' }} />
      </div>

    )
  } else {
    return <input className='bpp-input' style={{ width: '96%', ...style }} {...props} />
  }
}
