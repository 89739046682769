import store from 'store2'

const PROD = 'https://w2wqqf2jac.execute-api.us-east-1.amazonaws.com'
//const HOM = 'https://svleo46ul2.execute-api.us-east-1.amazonaws.com'

export default function bppFetch (url, options, isAppConnected, marvin) {
  if (isAppConnected) {
    const token = store.session.get('token')
    const authorization = token && { 'Authorization': token }
    let defaultHeader = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'parceiro': 'BPP', ...authorization } }

    if (marvin) {
      defaultHeader = { headers: { 'Content-Type': 'application/json' } }
    }

    options = { ...defaultHeader, ...options }
    const fetchUrl = url.slice(0, 4) === 'http' ? url : `${PROD + url}`

    return fetch(fetchUrl, options)
      .then(async response => {
        const result = ({ error: !response.ok, data: options.headers['Content-Type'] === 'application/json' ? await response.json() : await response.text(), response, request: { url, options } })
        console.log(result)
        store.session('token', result.data.token)
        if (result.data.cartaoId) {
          store.session('cartaoId', result.data.cartaoId)
        }
        if (result.data.contaId) {
          store.session('contaId', result.data.contaId)
        }
        if (result.data.pessoaTitularId) {
          store.session('idPessoa', result.data.pessoaTitularId)
        }
        if (result.data.contaPaiId) {
          store.session('contaPaiId', result.data.contaPaiId)
        }

        return result
      })
  }
  return Promise.resolve({ error: true, data: { erros: [{ mensagem: 'Sem conexão' }] }, request: { url, options } })
}
