import React from 'react'

import IconCardLock from '../../assets/icone_bloquear_cartao.svg'
import { flex } from '../../infra/flex'
import ENUM from '../../infra/constants'
import MainDesk from '../../components/desktop/mainDesk/MainDesk'
import TitleDesk from '../../components/desktop/titleDesk/TitleDesk'
import TextDesk from '../../components/desktop/textDesk/TextDesk'
import ButtonDesk from '../../components/desktop/buttonDesk/ButtonDesk'
import CardLockApi from '../../infra/api/CardLockApi'
import { useSelector } from 'react-redux'

export default function CardLockDesktop ({ isAppConnected, setOpen, history, ...props }) {
  const contaIdData = useSelector(state => state.global.contaId)

  let accountId = contaIdData

  function block () {
    return CardLockApi({ accountId, history, isAppConnected })
      .then(resultData => {
        if (resultData === 'error') {
          history.push({ pathname: '/erro-generico', state: { title: ENUM.ERROR_BLOQUEAR_CARTAO, isLogout: true } })
        } else {
          history.push('/cartao-bloqueado')
        }
        setOpen(false)
      })
  }

  return (
    <React.Fragment>
      <MainDesk {...flex(1, { padding: '15% 17%' })}>
        <img {...flex(0.3, { maxHeight: '25%' })} src={IconCardLock} alt={ENUM.ALT_CARD_LOCK} />
        <TitleDesk {...flex(0.2, { textAlign: 'center', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', color: 'var(--main_text)', fontFamily: 'Uni Sans Bold', fontSize: '32px' })} title={ENUM.TITLE_BLOQUEAR_CARTAO} />

        <TextDesk {...flex(0.2, { textAlign: 'center', justifyContent: 'center', alignItems: 'center', color: 'var(--main_text)', fontFamily: 'Uni Sans Regular', fontSize: '16px' })} message={ENUM.MSG_CONFIRMAR_BLOQUEAR_CARTAO} />
        <div {...flex(0.1)} />
        <footer {...flex(0.2, { justifyContent: 'space-between' })}>
          <ButtonDesk style={{ minHeight: '48px', height: 'auto', width: 'auto', lineHeight: '1px', flex: 0.48 }} onClick={() => setOpen(false)} title={ENUM.DANGER_TITLE_AGORA_NAO} />
          <ButtonDesk style={{ minHeight: '48px', height: 'auto', width: 'auto', lineHeight: '1px', flex: 0.48 }} onClick={block} danger title={ENUM.TITLE_BLOQUEAR} />
        </footer>
      </MainDesk>
    </React.Fragment>
  )
}
