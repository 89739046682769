import React from 'react'
import './main.css'

export default function Main ({ isBackground, children, list, ...props }) {
  if (isBackground) {
    return (
      <main
        className={'bpp-mainBackGround : bpp-main:before'}
        {...props}>
        {children}
      </main>
    )
  }

  return (
    <main
      className={list ? 'bpp-main bpp-main-list' : 'bpp-main'}
      {...props}>
      {children}
    </main>
  )
}
