import bppFetch from '../bpp-fetch'
import ENUM from '../constants'
import store from 'store2'

export default function CardLockApi ({ accountId, history, isAppConnected }) {
  let options = {
    method: 'POST',
    body: JSON.stringify({
      operacao: ENUM.OPERACAO_ALTERACAO_STATUS_CARTAO,
      valor: 0,
      dadosIntegracao: {
        status: ENUM.STATUS_BLOQUEADO
      },
      contaDestinoId: accountId
    })
  }

  let postOptions = { method: 'POST',
    body: JSON.stringify({
      transacao: ENUM.TRANSACAO_APROVACAO_KEY,
      valor: 0
    })
  }

  return bppFetch('/v1/operacao', options, isAppConnected)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        var rel = data.rel
        store.session('relHash', rel)

        return bppFetch('/v1/operacao/' + rel + '/transacao', postOptions, isAppConnected)
          .then(({ error, data, response, request }) => {
            if (error || data.erros) {
              return 'error'
            } else {
              return 'success'
            }
          })
          .catch(() => history.push({ pathname: '/erro-generico', state: { title: ENUM.ERROR_BLOQUEAR_CARTAO, isLogout: true } })
          )
      }
    })
    .catch(() => history.push({ pathname: '/erro-generico', state: { title: ENUM.ERROR_BLOQUEAR_CARTAO, isLogout: true } })
    )
}
