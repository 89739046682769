import { createAction } from '@reduxjs/toolkit'
export const UPDATE_EXTRACT_LIST = 'extract/UPDATE_EXTRACT_LIST'
export const UPDATE_EXTRACT_FILTERED_LIST = 'extract/UPDATE_EXTRACT_FILTERED_LIST'
export const UPDATE_EXTRACT_ITEM = 'extract/UPDATE_EXTRACT_ITEM'
export const UPDATE_OPERATION = 'extract/UPDATE_OPERATION'
export const UPDATE_CARTAO_ID = 'extract/UPDATE_CARTAO_ID'
export const UPDATE_CONTA_ID = 'extract/UPDATE_CONTA_ID'
export const UPDATE_ID_PESSOA = 'extract/UPDATE_ID_PESSOA'
export const UPDATE_CONTA_PAI_ID = 'extract/UPDATE_CONTA_PAI_ID'
export const UPDATE_RANGE = 'extract/RANGE'
export const UPDATE_SINCE_DATE = 'extract/SINCE_DATE'
export const UPDATE_UNTIL_DATE = 'extract/UNTIL_DATE'
export const CLEAR_APP = 'extract/CLEAR_APP'
export const UPDATE_PORTADOR_ID = 'extract/UPDATE_PORTADOR_ID'

export const updateExtractList = createAction(UPDATE_EXTRACT_LIST)

export const updateExtractFilteredList = createAction(UPDATE_EXTRACT_FILTERED_LIST)

export const updateExtractItem = createAction(UPDATE_EXTRACT_ITEM)

export const updateOperation = createAction(UPDATE_OPERATION)

export const updateCartaoId = createAction(UPDATE_CARTAO_ID)

export const updateContaId = createAction(UPDATE_CONTA_ID)

export const updateIdPessoa = createAction(UPDATE_ID_PESSOA)

export const updateContaPaiId = createAction(UPDATE_CONTA_PAI_ID)

export const updateRange = createAction(UPDATE_RANGE)

export const updateSinceDate = createAction(UPDATE_SINCE_DATE)

export const updateUntilDate = createAction(UPDATE_UNTIL_DATE)

export const clearAppExtract = createAction(CLEAR_APP)

export const updatePortadorId = createAction(UPDATE_PORTADOR_ID)
