import React from 'react'

import ErrorIcon from '../../assets/error.svg'
import { flex } from '../../infra/flex'
import ENUM from '../../infra/constants'
import MainDesk from '../../components/desktop/mainDesk/MainDesk'
import TitleDesk from '../../components/desktop/titleDesk/TitleDesk'
import TextDesk from '../../components/desktop/textDesk/TextDesk'
import ButtonDesk from '../../components/desktop/buttonDesk/ButtonDesk'
import Card from '../../components/desktop/card/Card'
import HeaderDesk from '../../components/desktop/headerDesk/HeaderDesk'
import BPPLogo from '../../assets/full_logo_white.svg'

export default function ErrorScreenDesktop (props) {
  const { isLogout, title = ENUM.ERRO_EFETUAR_OPERACAO } = props.location.state || {}

  const topContent = (
    <center><img {...flex(0.1, { height: '75%' })} src={BPPLogo} alt={ENUM.ALT_BBP_LOGO} /></center>
  )

  return (
    <React.Fragment>
      <HeaderDesk {...flex(0.1, { justifyContent: 'center' })} topContent={topContent} />
      <MainDesk {...flex(0.9)}>
        <Card {...flex(0.8, { alignSelf: 'center', minHeight: '260px' })} >
          <div style={{ flex: 0.4, justifyContent: 'center', alignSelf: 'center', display: 'flex', alignItems: 'flex-end' }}>
            <img style={{ objectFit: 'contain' }} src={ErrorIcon} alt='Erro' />
          </div>
          <TitleDesk {...flex(0.2, { textAlign: 'center', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', color: 'var(--main_text)', fontSize: 30 })} title={title} />
          <TextDesk {...flex(0.2, { textAlign: 'center', justifyContent: 'center', color: 'var(--main_text)', fontSize: 16 })} message={ENUM.MSG_TENTE_MAIS_TARDE} />

          <footer {...flex(0.2, { alignItems: 'flex-end' })}>
            <ButtonDesk title={ENUM.BTN_TITLE_OK_ENTENDI} onClick={() => props.history.push(isLogout ? '' : 'inicio')} />
          </footer>
        </Card>
      </MainDesk>
    </React.Fragment>
  )
}
