import React from 'react'

import Wifi from '../../assets/wifi.svg'

import MainDesk from '../../components/desktop/mainDesk/MainDesk'
import TitleDesk from '../../components/desktop/titleDesk/TitleDesk'
import ButtonDesk from '../../components/desktop/buttonDesk/ButtonDesk'
import TextDesk from '../../components/desktop/textDesk/TextDesk'
import ENUM from '../../infra/constants'

import { flex } from '../../infra/flex'

export default function NoConnectionDesktop ({ callbackButton }) {
  return (
    <React.Fragment>
      <MainDesk modal {...flex()}>
        <img {...flex(0.2)} src={Wifi} alt='Wifi' />
        <TitleDesk {...flex(0.3, { marginTop: 20, display: 'block', textAlign: 'center', fontFamily: 'Uni Sans Bold', fontSize: 32, color: 'var(--main_text)', justifyContent: 'center' })} title={ENUM.TITLE_SEM_INTERNET} />
        <TextDesk {...flex(0.2, { display: 'block', textAlign: 'center', fontFamily: 'Uni Sans Regular', fontSize: 16, justifyContent: 'center' })} message={ENUM.MSG_VERIFIQUE_CONEXAO} />
        <footer {...flex(0.3, { display: 'block', alignItems: 'flex-end' })}>
          <ButtonDesk style={{ marginTop: 20 }} title={ENUM.TITLE_ENTENDI} onClick={callbackButton} />
        </footer>
      </MainDesk>
    </React.Fragment>
  )
}
