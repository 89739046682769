import React from 'react'
import './buttonDesk.css'
import LoadingDesk from '../loadingDesk/LoadingDesk'
import { flex } from '../../../infra/flex'

export default function ButtonDesk ({ className = '', disabled, title, isLoading, danger, ...props }) {
  const computedClassName = ['', className, danger && 'bpp-danger', isLoading && 'bpp-buttonDesk-loading'].join(' ')
  if (disabled) {
    return <button className={`bpp-buttonDesk${computedClassName}`} disabled={disabled || isLoading} style={{ borderColor: 'transparent', backgroundColor: 'var(--disabled_btn)' }} {...props}>
      {isLoading ? <div {...flex(1, { alignItems: 'center', justifyContent: 'center' })}>
        <LoadingDesk small />
      </div> : title }
    </button>
  }
  return <button className={`bpp-buttonDesk${computedClassName}`} disabled={disabled || isLoading} {...props}>
    {isLoading ? <div {...flex(1, { alignItems: 'center', justifyContent: 'center' })}>
      <LoadingDesk small />
    </div> : title }
  </button>
}
