import React from 'react'
import TextDesk from '../textDesk/TextDesk'
import { flex } from '../../../infra/flex'
import ENUM from '../../../infra/constants'

export default function ImageTouchableDesk ({ actionLeave, title, src, ...props }) {
  return (
    <div {...flex(1, { flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', minWidth: '18%', alignSelf: 'center' })} {...props}>
      <img src={src} onClick={actionLeave} alt={ENUM.ALT_ICONE_LOGOUT} />
      <TextDesk onClick={actionLeave} message={title} style={{ color: 'white', textAlign: 'center', fontSize: 14, alignSelf: 'center' }} />
    </div>
  )
}
