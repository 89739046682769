import { createReducer } from '@reduxjs/toolkit'
import { UPDATE_ACCOUNT_DATA, UPDATE_PHONE } from './actions'
import { CLEAR_APP } from '../app/actions'

const initialState = {
  data: undefined,
  phone: undefined
}

const accountReducer = createReducer(initialState, {
  [UPDATE_ACCOUNT_DATA]: (state, action) => {
    state.data = action.payload
  },
  [UPDATE_PHONE]: (state, action) => {
    state.phone = action.payload
  },
  [CLEAR_APP]: (state, action) => {
    return initialState
  }
})

export default accountReducer
