import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppDisconnectedModal, setAppConnected } from '../store/modules/app/actions'

export default function useConnectionStatus () {
  const isAppConnected = useSelector(state => state.app.connected)

  const dispatch = useDispatch()
  const disconnect = useCallback((status) => dispatch(setAppDisconnectedModal(status)), [])
  const handleConnection = useCallback((status) => dispatch(setAppConnected(status)), [])

  function handleStatusOffline () {
    disconnect()
    handleConnection(false)
  }

  function handleStatusOnline () {
    handleConnection(true)
  }

  useEffect(() => {
    window.addEventListener('online', handleStatusOnline)
    window.addEventListener('offline', handleStatusOffline)
    return () => {
      window.removeEventListener('online', handleStatusOnline)
      window.removeEventListener('offline', handleStatusOffline)
    }
  })

  return [isAppConnected]
}
