import React from 'react'
import './navigationDesk.css'
import backArrow from '../../../assets/combined_shape.svg'
import backArrowBlue from '../../../assets/combined_shape_blue.svg'
import ENUM from '../../../infra/constants'
import TextDesk from '../textDesk/TextDesk'

export default function NavigationDesk ({ isLoading, isWhiteHeader, title, history, isGoback, ...props }) {
  if (isWhiteHeader) {
    return (
      <div className='bpp-navigationDesk-container-white' {...props}>
        <div className='bpp-navigationDesk'>
          <img src={backArrowBlue} style={{ marginTop: -3 }} alt={ENUM.ALT_BACK} onClick={() => history.push({ pathname: '/inicio' })} />
          <TextDesk style={{ fontFamily: 'Uni Sans Regular', color: 'var(--accent_main_complementary)' }} message={title} onClick={() => history.push({ pathname: '/inicio' })} />
        </div>
      </div>
    )
  }

  if (isGoback) {
    return (
      <div className='bpp-navigationDesk-container-white' {...props}>
        <div className='bpp-navigationDesk'>
          <img src={backArrowBlue} style={{ marginTop: -3 }} alt={ENUM.ALT_BACK} onClick={() => history.goBack()} />
          <TextDesk style={{ fontFamily: 'Uni Sans Regular', color: 'var(--accent_main_complementary)' }} message={title} onClick={() => history.goBack()} />
        </div>
      </div>
    )
  }

  return (
    <div className='bpp-navigationDesk-container' {...props}>
      <div className='bpp-navigationDesk'>
        <img style={{ marginTop: -3 }} src={backArrow} alt={ENUM.ALT_BACK} onClick={!isLoading ? history : null} />
        <TextDesk style={{ fontFamily: 'Uni Sans Regular' }} message={title} onClick={() => history.push({ pathname: '/inicio' })} />
      </div>
    </div>
  )
}
