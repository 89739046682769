import { createReducer } from '@reduxjs/toolkit'
import { UPDATE_CARD_DATA } from './actions'
import { CLEAR_APP } from '../app/actions'

const initialState = {
  data: undefined,
  phone: undefined
}

const cardReducer = createReducer(initialState, {
  [UPDATE_CARD_DATA]: (state, action) => {
    state.data = action.payload
  },
  [CLEAR_APP]: (state, action) => {
    return initialState
  }
})

export default cardReducer
