import React from 'react'
import './headerDesk.css'

export default function HeaderDesk ({ topContent, leftContent, rightContent, big, ...props }) {
  if (big) {
    return (
      <div className='bpp-headerBigDesk-container' {...props}>
        {topContent}
        <div className='bpp-headerBigDesk-content'>
          {leftContent}
          {rightContent}
        </div>
      </div>
    )
  }
  return (
    <div className='bpp-headerDesk-container' {...props}>
      {topContent}
    </div>
  )
}
