import bppFetch from '../bpp-fetch'

export default function ChangeNickApi ({ nick, accountId, isAppConnected, ...props }) {
  let options = {
    method: 'PATCH',
    body: JSON.stringify({
      apelido: nick
    })
  }

  return bppFetch('/v1/conta/' + accountId, options, isAppConnected)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        let nickObject = { nick }
        return nickObject
      }
    })
    .catch(() => { return 'error-generico' })
}
