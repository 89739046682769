import React, { useState, useEffect } from 'react'
import './modalDesk.css'

export default function ModalDesk ({ isOpen, setOpen, children, isDropdown, className, ...props }) {
  const [needRender, setNeedRender] = useState(isOpen)
  let timeoutId

  useEffect(() => {
    (isOpen && !needRender) && setNeedRender(isOpen)
    if (!isOpen && needRender) {
      timeoutId = setTimeout(() => {
        setNeedRender(false)
      }, 800)
      return () => clearTimeout(timeoutId)
    }
  })

  if (!needRender) {
    return null
  }

  let animationIn = 'flipInX fast'
  let animationOut = 'fadeOut faster'

  return <div className='bpp-modalDesk'>
    <div className={`bpp-modalDesk-background animated ${isOpen ? 'fadeInModalDesk faster' : 'fadeOutModalDesk'} `} />
    <div {...props} className={`bpp-modalDesk-content animated ${isOpen ? animationIn : animationOut} ${className || ''}`}>
      <React.Fragment>
        {isDropdown && <div style={{
          width: 15,
          background: 'white',
          height: 15,
          transform: 'rotate(45deg)',
          position: 'absolute',
          left: 12,
          top: -5
        }} />}
        <div className={`bpp-modalDesk-content-radius`}>
          {children}
        </div>
      </React.Fragment>
    </div>
  </div>
}
