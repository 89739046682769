import { createReducer } from '@reduxjs/toolkit'
import ENUM from '../../../infra/constants'
import {
  UPDATE_EXTRACT_LIST,
  UPDATE_EXTRACT_FILTERED_LIST,
  UPDATE_UNTIL_DATE,
  UPDATE_SINCE_DATE,
  UPDATE_OPERATION,
  UPDATE_CARTAO_ID,
  UPDATE_CONTA_ID,
  UPDATE_ID_PESSOA,
  UPDATE_CONTA_PAI_ID,
  UPDATE_PORTADOR_ID,
  UPDATE_EXTRACT_ITEM,
  UPDATE_RANGE
} from './actions'
import { CLEAR_APP } from '../app/actions'

const initialState = {
  list: undefined,
  listFiltered: undefined,
  // operation: ENUM.EXTRACT_FILTER_PENDENTE,
  cartaoId: undefined,
  contaId: undefined,
  idPessoa: undefined,
  contaPaiId: undefined,
  operation: ENUM.EXTRACT_FILTER_TODOS,
  range: '60',
  untilDate: undefined,
  sinceDate: undefined,
  portadorId: undefined
}

const reducer = createReducer(initialState, {
  [UPDATE_EXTRACT_LIST]: (state, action) => {
    state.list = action.payload
  },
  [UPDATE_EXTRACT_FILTERED_LIST]: (state, action) => {
    state.listFiltered = action.payload
  },
  [UPDATE_UNTIL_DATE]: (state, action) => {
    state.untilDate = action.payload
  },
  [UPDATE_SINCE_DATE]: (state, action) => {
    state.sinceDate = action.payload
  },
  [UPDATE_OPERATION]: (state, action) => {
    state.operation = action.payload
  },
  [UPDATE_CARTAO_ID]: (state, action) => {
    state.cartaoId = action.payload
  },
  [UPDATE_CONTA_ID]: (state, action) => {
    state.contaId = action.payload
  },
  [UPDATE_ID_PESSOA]: (state, action) => {
    state.idPessoa = action.payload
  },
  [UPDATE_CONTA_PAI_ID]: (state, action) => {
    state.contaPaiId = action.payload
  },
  [UPDATE_PORTADOR_ID]: (state, action) => {
    state.portadorId = action.payload
  },
  [UPDATE_EXTRACT_ITEM]: (state, action) => {
    let lista = state.list || []
    let item = action.payload

    for (let i = 0; i < lista.length; i++) {
      if (lista[i].bppMovimentoProcessadoraId === item.bppMovimentoProcessadoraId) {
        lista[i] = item
      }
    }
    state.list = lista
  },
  [UPDATE_RANGE]: (state, action) => {
    state.range = action.payload
  },
  [CLEAR_APP]: (state, action) => {
    return initialState
  }

})

export default reducer
