import { createAction } from '@reduxjs/toolkit'
export const UPDATE_CARTAO_ID = 'global/UPDATE_CARTAO_ID'
export const UPDATE_CONTA_ID = 'global/UPDATE_CONTA_ID'
export const UPDATE_ID_PESSOA = 'global/UPDATE_ID_PESSOA'
export const UPDATE_CONTA_PAI_ID = 'global/UPDATE_CONTA_PAI_ID'
export const UPDATE_PORTADOR_ID = 'global/UPDATE_PORTADOR_ID'

export const updateCartaoId = createAction(UPDATE_CARTAO_ID)

export const updateContaId = createAction(UPDATE_CONTA_ID)

export const updateIdPessoa = createAction(UPDATE_ID_PESSOA)

export const updateContaPaiId = createAction(UPDATE_CONTA_PAI_ID)

export const updatePortadorId = createAction(UPDATE_PORTADOR_ID)
