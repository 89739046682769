import { createReducer } from '@reduxjs/toolkit'
import store from 'store2'
import { SET_APP_UPDATED, SET_APP_CONNECTED, SET_APP_DISCONNECTED_MODAL, CLEAR_APP } from './actions'

const initialState = {
  updated: false,
  connected: window.navigator && window.navigator.onLine,
  disconnectedModal: false
}
const reducer = createReducer(initialState, {
  [SET_APP_UPDATED]: (state, action) => {
    state.updated = action.payload
  },
  [SET_APP_CONNECTED]: (state, action) => {
    state.connected = action.payload
  },
  [SET_APP_DISCONNECTED_MODAL]: (state, action) => {
    state.disconnectedModal = action.payload
  },
  [CLEAR_APP]: (state, action) => {
    store.session('token', false)
    return initialState
  }

})

export default reducer
