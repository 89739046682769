import bppFetch from '../bpp-fetch'
import Axios from 'axios'
const optionsGet = { method: 'GET' }

export function getComboClientesApi (idPessoa, connection) {
  var urlCombo = '/v1/pessoa/' + idPessoa + '/prestacaoConta/projeto?status=ativo'
  return bppFetch(urlCombo, optionsGet, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch(() => { return 'error' })
}

export function getComboDespesaApi (idPessoa, connection) {
  var urlCombo = '/v1/pessoa/' + idPessoa + '/prestacaoConta/classificacao?status=ativo'
  return bppFetch(urlCombo, optionsGet, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch(() => { return 'error' })
}

export function getPrestacaoContaApi (idPessoa, contaPaiId, idProcessadora, connection) {
  var urlCombo = '/v1/pessoa/' + idPessoa + '/prestacaoConta?contaPaiId=' + contaPaiId + '&bppMovimentoProcessadoraId=' + idProcessadora
  return bppFetch(urlCombo, optionsGet, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch((e) => {
      return 'catch'
    })
}

export function getPrestacaoContaHash (idPessoa, hash, connection) {
  var urlCombo = '/v1/pessoa/' + idPessoa + '/prestacaoConta/' + hash
  return bppFetch(urlCombo, optionsGet, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch((e) => {
      return 'catch'
    })
}

export function prestaContaApi (url, options, connection) {
  return bppFetch(url, options, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return 'success'
      }
    })
    .catch(() => { return 'error' })
}

export function getImagePutUrlApi (type, connection) {
  let optionsPoster = {
    method: 'POST',
    body: JSON.stringify({
      contentType: type
    })
  }

  let url = '/v1/arquivo'

  return bppFetch(url, optionsPoster, connection)
    .then(async ({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch(() => {
      return 'catch'
    }
    )
}

export function sendImageFileApi (urlToCall, dynamicFile, options) {
  return Axios.put(urlToCall, dynamicFile, options)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return 'success'
      }
    })
    .catch(() => {
      return 'catch'
    })
}
