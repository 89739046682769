import React from 'react'
import './inputDesk.css'
import ChevronRight from '../../../assets/chevron_right_solid.svg'
import { flex } from '../../../infra/flex'
import ENUM from '../../../infra/constants'

export default function InputDesk ({ bold, checked, chevron, danger, greyBorder, style, placeholder, account, ...props }) {
  let colorPlace = !checked === true ? 'var(--main_text)' : 'var(--accent_main_complementary)'

  if (danger) {
    return (
      <div>
        <p style={{ color: colorPlace, fontFamily: 'Uni Sans Light', fontSize: '16PX' }}>{placeholder}</p>
        <input className='bpp-inputDesk-danger' style={{ width: '96%', ...style }} {...props} />
      </div>
    )
  }
  if (greyBorder) {
    return <input className='bpp-InputDesk-grey' style={{ width: '96%', ...style }} {...props} />
  }
  if (chevron) {
    return (

      <div {...flex(0)}>
        <input {...flex(0.9, style)} className='bpp-InputDesk-chevron' {...props} />
        <img {...flex(0.1)} src={ChevronRight} alt={ENUM.ALT_ICONE_CHEVRON_RIGHT} style={{ height: '14px', width: '8px' }} />
      </div>

    )
  }
  if (account) {
    return (

      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <p style={{ color: colorPlace, fontFamily: 'Uni Sans Light', fontSize: '16PX' }}>{placeholder}</p>
        <textarea className='bpp-InputDesk' style={{ borderColor: 'var(--gray_light_default)', width: '96%', ...style }} {...props} />
      </div>

    )
  } else {
    return <div>
      <p style={{ color: colorPlace, fontFamily: bold ? 'Uni Sans Bold' : 'Uni Sans Light', fontSize: '16PX' }}>{placeholder}</p>
      <input className='bpp-InputDesk' style={{ borderColor: colorPlace, width: '96%', ...style }} {...props} />

    </div>
  }
}
