import React from 'react'
import './loadingDesk.css'
import './loadingWhite.css'
import './loadingGreen.css'
import './loadingBlue.css'

export default function LoadingDesk ({ blue, small, green, dot, ...props }) {
  let defaultColor = 'white'
  if (green) {
    defaultColor = 'green'
  } else if (blue) {
    defaultColor = 'blue'
  } else if (dot) {
    defaultColor = 'dot'
  }

  const IphoneLoading = () => small
    ? <div className={`lds-spinner-${defaultColor}`} {...props}><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /></div>
    : <div className='lds-spinner' {...props} ><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /></div>

  const AndroidLoading = () => small
    ? <div className={`lds-ring-${defaultColor}`} {...props}><div /><div /><div /><div /></div>
    : <div className='lds-ring' {...props}><div /><div /><div /><div /></div>

  return window.isMobile.apple.device ? <IphoneLoading /> : <AndroidLoading />
}
