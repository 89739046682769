import React, { Suspense, useCallback, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import Loading from './components/mobile/loading/Loading'
import Main from './components/mobile/main/Main'
import { flex } from './infra/flex'
import Modal from './components/mobile/modal/Modal'
import store from 'store2'

// import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDispatch, useSelector } from 'react-redux'
import { isAppDisconnectedModal } from './store/modules/app/selectors'
import { setAppDisconnectedModal } from './store/modules/app/actions'

import NoConnection from './screens/mobile/NoConnection'
import NoConnectionDesktop from './screens/desktop/NoConnectionDesktop'
import ErrorScreen from './screens/mobile/ErrorScreen'
import ErrorScreenDesktop from './screens/desktop/ErrorScreenDesktop'
import useConnectionStatus from './infra/connection'
import RegisterPassword from './screens/mobile/RegisterPassword'
import RegisterPasswordDesktop from './screens/desktop/RegisterPasswordDesktop'
import ModalDesk from './components/desktop/modalDesk/ModalDesk'
import CardLockDesktop from './screens/desktop/CardLockDesktop'
import ChangeNickDesktop from './screens/desktop/ChangeNickDesktop'
import HandleAccountabilityHash from './screens/mobile/HandleAccountabilityHash'
const widthToDesk = '768'

const Login = React.lazy(() => import('./screens/mobile/Login'))
const LoginDesktop = React.lazy(() => import('./screens/desktop/LoginDesktop'))
const ChangePassword = React.lazy(() =>
  import('./screens/mobile/ChangePassword')
)
const ChangePasswordDesktop = React.lazy(() =>
  import('./screens/desktop/ChangePasswordDesktop')
)
const ConfirmPhone = React.lazy(() => import('./screens/mobile/ConfirmPhone'))
const ConfirmPhoneDesktop = React.lazy(() =>
  import('./screens/desktop/ConfirmPhoneDesktop')
)
const Cliente = React.lazy(() => import('./screens/mobile/Cliente'))
const Despesa = React.lazy(() => import('./screens/mobile/Despesa'))
const Help = React.lazy(() => import('./screens/mobile/Help'))
const HelpDesktop = React.lazy(() => import('./screens/desktop/HelpDesktop'))
const Inicio = React.lazy(() => import('./screens/mobile/Inicio'))
const InicioDesktop = React.lazy(() =>
  import('./screens/desktop/InicioDesktop')
)
const SuccessPassword = React.lazy(() =>
  import('./screens/mobile/SuccessPassword')
)
const SuccessPasswordDesktop = React.lazy(() =>
  import('./screens/desktop/SuccessPasswordDesktop')
)
const CardLocked = React.lazy(() => import('./screens/mobile/CardLocked'))
const CardLockedDesktop = React.lazy(() =>
  import('./screens/desktop/CardLockedDesktop')
)
const Welcome = React.lazy(() => import('./screens/mobile/Welcome'))
const WelcomeDesktop = React.lazy(() =>
  import('./screens/desktop/WelcomeDesktop')
)
const RegisterPhone = React.lazy(() =>
  import('./screens/mobile/RegisterPhone')
)
const RegisterPhoneDesktop = React.lazy(() =>
  import('./screens/desktop/RegisterPhoneDesktop')
)
const PendingActivation = React.lazy(() =>
  import('./screens/mobile/PendingActivation')
)
const PendingActivationDesktop = React.lazy(() =>
  import('./screens/desktop/PendingActivationDesktop')
)
const MakeAccountability = React.lazy(() =>
  import('./screens/mobile/MakeAccountability')
)
const MakeAccountabilityDesktop = React.lazy(() =>
  import('./screens/desktop/MakeAccountabilityDesktop')
)
const HelpChannels = React.lazy(() => import('./screens/mobile/HelpChannels'))
const HelpChannelsDesktop = React.lazy(() =>
  import('./screens/desktop/HelpChannelsDesktop')
)
const DetailsOperation = React.lazy(() =>
  import('./screens/mobile/DetailsOperation')
)
const DetailsOperationDesktop = React.lazy(() =>
  import('./screens/desktop/DetailsOperationDesktop')
)
// const UpdateApp = React.lazy(() => import('./screens/mobile/UpdateApp'))

const prontoAtivacao = store.session.get('prontoParaAtivar')

const Fallback = () => (
  <Main {...flex(1, { justifyContent: 'center', alignItems: 'center' })}>
    <Loading />
  </Main>
)

function PrivateRoute ({ render: componentRender, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        store.session.get('token') ? (
          componentRender(props)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default function Routes ({ ...props }) {
  const isAppDisconnectedModal = useSelector(state => state.app.disconnectedModal)

  const dispatch = useDispatch()
  const hideConnectionModal = useCallback(
    () => dispatch(setAppDisconnectedModal(false)),
    []
  )

  const [isAppConnected] = useConnectionStatus()
  const [isOpenCardLock, setOpenCardLock] = useState(false)
  const [isOpenChangeNick, setOpenChangeNick] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)
  function handleResize () {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  function renderModal () {
    if (width <= widthToDesk) {
      return (
        <Modal isOpen={isAppDisconnectedModal} setOpen={hideConnectionModal}>
          <NoConnection callbackButton={hideConnectionModal} />
        </Modal>
      )
    }
    return (
      <ModalDesk isOpen={isAppDisconnectedModal} setOpen={hideConnectionModal}>
        <NoConnectionDesktop callbackButton={hideConnectionModal} />
      </ModalDesk>
    )
  }

  function renderModalCardLockDesk () {
    if (width > widthToDesk) {
      return (
        <ModalDesk isOpen={isOpenCardLock} setOpen={setOpenCardLock}>
          <CardLockDesktop
            isAppConnected={isAppConnected}
            setOpen={setOpenCardLock}
          />
        </ModalDesk>
      )
    }
    return undefined
  }

  function setOpenChangeNickBefore () {
    setOpenChangeNick(false)
  }

  function renderModalChangeNick () {
    if (width > widthToDesk) {
      return (
        <ModalDesk isOpen={isOpenChangeNick} setOpen={setOpenChangeNick}>
          <ChangeNickDesktop
            isAppConnected={isAppConnected}
            setOpen={setOpenChangeNickBefore}
          />
        </ModalDesk>
      )
    }
    return undefined
  }

  const customProps = { isAppConnected, setOpenCardLock, setOpenChangeNick }
  return (
    <Router>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route
            path='/'
            exact
            render={props =>
              width <= widthToDesk ? (
                <Login {...props} {...customProps} />
              ) : (
                <LoginDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/alterar-pin'
            exact
            render={props =>
              width <= widthToDesk ? (
                <ChangePassword {...props} {...customProps} />
              ) : (
                <ChangePasswordDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/confirmar-telefone'
            exact
            render={props =>
              width <= widthToDesk ? (
                <ConfirmPhone {...props} {...customProps} />
              ) : (
                <ConfirmPhoneDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/cliente'
            render={props => <Cliente {...props} {...customProps} />}
          />
          <PrivateRoute
            path='/despesa'
            render={props => <Despesa {...props} {...customProps} />}
          />
          <PrivateRoute
            path='/ajuda'
            exact
            render={props =>
              width <= widthToDesk ? (
                <Help {...props} {...customProps} />
              ) : (
                <HelpDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/inicio'
            render={props =>
              width <= widthToDesk ? (
                prontoAtivacao ? (
                  <Welcome {...props} />
                ) : (
                  <Inicio {...props} {...customProps} />
                )
              ) : prontoAtivacao ? (
                <WelcomeDesktop {...props} />
              ) : (
                <InicioDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/abrir-camera'
            exact
            render={props =>
              width <= widthToDesk ? (
                <MakeAccountability {...props} {...customProps} />
              ) : (
                <MakeAccountabilityDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/sucesso-pin'
            exact
            render={props =>
              width <= widthToDesk ? (
                <SuccessPassword {...props} {...customProps} />
              ) : (
                <SuccessPasswordDesktop {...props} {...customProps} />
              )
            }
          />
          <Route
            path='/erro-generico'
            exact
            render={props =>
              width <= widthToDesk ? (
                <ErrorScreen {...props} {...customProps} />
              ) : (
                <ErrorScreenDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/cartao-bloqueado'
            render={props =>
              width <= widthToDesk ? (
                <CardLocked {...props} {...customProps} />
              ) : (
                <CardLockedDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/bem-vindo'
            render={props =>
              width <= widthToDesk ? (
                <Welcome {...props} {...customProps} />
              ) : (
                <WelcomeDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/cadastra-telefone'
            render={props =>
              width <= widthToDesk ? (
                <RegisterPhone {...props} {...customProps} />
              ) : (
                <RegisterPhoneDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/cadastra-pin'
            render={props =>
              width <= widthToDesk ? (
                <RegisterPassword {...props} {...customProps} />
              ) : (
                <RegisterPasswordDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/pendente-ativacao'
            render={props =>
              width <= widthToDesk ? (
                <PendingActivation {...props} {...customProps} />
              ) : (
                <PendingActivationDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/canais-ajuda'
            render={props =>
              width <= widthToDesk ? (
                <HelpChannels {...props} {...customProps} />
              ) : (
                <HelpChannelsDesktop {...props} {...customProps} />
              )
            }
          />
          <PrivateRoute
            path='/detalhes-operacao'
            render={props =>
              width <= widthToDesk ? (
                <DetailsOperation {...props} {...customProps} />
              ) : (
                <DetailsOperationDesktop {...props} {...customProps} />
              )
            }
          />
          <Route
            path='/prestacao/:hash'
            render={props =>
              (
                <HandleAccountabilityHash {...props} {...customProps} />
              )
            }
          />
        </Switch>
        {renderModal()}
        {renderModalCardLockDesk()}
        {renderModalChangeNick()}
      </Suspense>
    </Router>
  )
}
