import React from 'react'
import ReactDOM from 'react-dom'
import './reset.css'
import './index.css'
import 'animate.css'
import * as serviceWorker from './serviceWorker'
import Routes from './Routes'
import isMobileJS from 'ismobilejs'

import { Provider } from 'react-redux'
import store from './store'
import { setAppUpdated } from './store/modules/app/actions'

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt-BR')
window.isMobile = isMobileJS

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register({
  onUpdate: () => store.dispatch(setAppUpdated())
})
