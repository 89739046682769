import React from 'react'
import { flex } from '../../../infra/flex'
import ImageTouchableDesk from '../imageTouchableDesk/ImageTouchableDesk'
import ENUM from '../../../infra/constants'
import BPPLogo from '../../../assets/full_logo_white.svg'
import IconLogout from '../../../assets/icone_logout.svg'

export default function TopContent ({ history, danger, message, ...props }) {
  function logOut () {
    history.push('/')
  }

  return (
    <div {...flex(0.2, { justifyContent: 'space-between', alignItems: 'center', contain: 'size', maxHeight: '5vh', minHeight: '5vh' })}>
      <img src={BPPLogo} alt={ENUM.ALT_BBP_LOGO} />
      <ImageTouchableDesk style={{ width: '6.5vh', display: 'flex', cursor: 'pointer', justifyContent: 'space-between' }} title={ENUM.TITLE_SAIR} src={IconLogout} actionLeave={logOut} />
    </div>
  )
}
