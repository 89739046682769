import React from 'react'
import './navigation.css'
import backArrow from '../../../assets/combined_shape.svg'
import backArrowBlue from '../../../assets/combined_shape_blue.svg'
import ENUM from '../../../infra/constants'

export default function Navigation ({ isLoading, isWhiteHeader, title, handleBack = () => {}, ...props }) {
  if (isWhiteHeader) {
    return (
    // <div className='bpp-navigation-container-white' {...props}>
    //   <div className='bpp-navigation' style={{ color: 'black' }}>
    //     <img src={chevronLeft} alt='back' onClick={handleBack} />
    //     {title}
    //   </div>
    // </div>
      <div className='bpp-navigation-container-white' {...props}>
        <div className='bpp-navigation'>
          <img src={backArrowBlue} style={{ marginTop: -3 }} alt={ENUM.ALT_BACK} onClick={handleBack} />
          <span style={{ fontFamily: 'Uni Sans Regular', color: 'var(--accent_main_complementary)' }}>{title}</span>
        </div>
      </div>
    )
  }

  return (
    <div className='bpp-navigation-container' {...props}>
      <div className='bpp-navigation'>
        <img style={{ marginTop: -3 }} src={backArrow} alt={ENUM.ALT_BACK} onClick={!isLoading ? handleBack : null} />
        <span style={{ fontFamily: 'Uni Sans Regular' }}>{title}</span>
      </div>
    </div>
  )
}
