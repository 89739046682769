import React from 'react'

import Wifi from '../../assets/wifi.svg'

import Main from '../../components/mobile/main/Main'
import Title from '../../components/mobile/title/Title'
import Button from '../../components/mobile/button/Button'
import Text from '../../components/mobile/text/Text'
import ENUM from '../../infra/constants'

import { flex } from '../../infra/flex'

export default function NoConnection ({ callbackButton }) {
  return (
    <React.Fragment>
      <Main {...flex()}>
        <img {...flex(0.2)} src={Wifi} alt='Wifi' />
        <Title {...flex(0.2, { textAlign: 'center', fontWeight: 'bold', justifyContent: 'center' })} title={ENUM.TITLE_SEM_INTERNET} />
        <Text {...flex(0.2, { textAlign: 'center', justifyContent: 'center' })} message={ENUM.MSG_VERIFIQUE_CONEXAO} />
        <footer {...flex(0.3, { alignItems: 'flex-end' })}>
          <Button title={ENUM.TITLE_ENTENDI} onClick={callbackButton} />
        </footer>
      </Main>
    </React.Fragment>
  )
}
