import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import IconEdit from '../../assets/edit-regular-no-color.svg'
import { flex } from '../../infra/flex'
import ENUM from '../../infra/constants'
import TitleDesk from '../../components/desktop/titleDesk/TitleDesk'
import TextDesk from '../../components/desktop/textDesk/TextDesk'
import ButtonDesk from '../../components/desktop/buttonDesk/ButtonDesk'
import MainDesk from '../../components/desktop/mainDesk/MainDesk'
import InputDesk from '../../components/desktop/inputDesk/InputDesk'
import ChangeNickApi from '../../infra/api/ChangeNickApi'
import { updateAccountData } from '../../store/modules/account/actions'

export default function ChangeNickDesktop ({ isAppConnected, setOpen, history, ...props }) {
  const accountData = useSelector(state => state.account.data)
  const contaIdData = useSelector(state => state.global.contaId)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const saveAccountData = useCallback((data) => dispatch(updateAccountData(data)), [accountData])
  let accountId = contaIdData

  const [nick, setNick] = useState('')

  function onKeyInputNick (e) {
    var mEvent = e
    var mPressed = mEvent.keyCode
    if (mPressed === 13) {
      doChangeNick()
    }
    return true
  }
  function changeNick (nickValue) {
    const re = /[^]/g
    if (nickValue === '' || re.test(nickValue)) {
      setNick(nickValue)
    }
  }

  async function doChangeNick () {
    setLoading(true)

    return ChangeNickApi({ nick, isAppConnected, accountId }).then(retorno => {
      if (retorno === 'error') {
        setErrorMsg(ENUM.ERRO_NICK)
      } else if (retorno === 'error-generico') {
        history.push({ pathname: '/erro-generico' })
      } else {
        setLoading(false)

        accountData.apelido = nick.toUpperCase()
        saveAccountData(accountData)

        setOpen(false)
      }
      setLoading(false)
    })
  }

  return (
    <React.Fragment>
      <MainDesk {...flex(1, { padding: '15% 17%' })}>
        <img {...flex(0.3, { height: '25%', maxWidth: '20%', alignSelf: 'center' })} src={IconEdit} alt={ENUM.ALT_ICONE_EDITAR} />
        <TitleDesk {...flex(0.2, { marginBottom: '3vh', textAlign: 'center', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', color: 'var(--main_text)', fontFamily: 'Uni Sans Bold', fontSize: '30px' })} title={ENUM.TITLE_APELIDO} />

        <InputDesk {...flex(0.2, { marginBottom: '3vh' })} onKeyUp={e => onKeyInputNick(e)} placeholder={ENUM.PLACEHOLDER_NICK} type={'text'} className='changeNick' maxLength='25' value={nick} onChange={e => changeNick(e.target.value)} />
        <TextDesk {...flex(0.1)} danger message={errorMsg} />
        <footer {...flex(0.2, { justifyContent: 'space-between' })}>
          <ButtonDesk isLoading={isLoading} style={{ minHeight: '48px', height: 'auto', width: 'auto', lineHeight: '1px', flex: 0.48 }} onClick={() => setOpen(false)} danger title={ENUM.BTN_DANGER_TITLE_AGORA_NAO} />
          <ButtonDesk isLoading={isLoading} style={{ minHeight: '48px', height: 'auto', width: 'auto', lineHeight: '1px', flex: 0.48 }} title={ENUM.BTN_TITLE_CONFIRMAR} onClick={() => doChangeNick()} />
        </footer>
      </MainDesk>
    </React.Fragment>
  )
}
