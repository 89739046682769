import React from 'react'

import Main from '../../components/mobile/main/Main'
import Title from '../../components/mobile/title/Title'
import Button from '../../components/mobile/button/Button'
import Text from '../../components/mobile/text/Text'
import ErrorIcon from '../../assets/error.svg'
import { flex } from '../../infra/flex'
import ENUM from '../../infra/constants'

export default function ErrorScreen (props) {
  const { isLogout, title = ENUM.ERRO_EFETUAR_OPERACAO } = props.location.state || {}
  return (
    <React.Fragment>
      <Main {...flex()}>
        <div style={{ flex: 0.4, justifyContent: 'center', alignSelf: 'center', display: 'flex', alignItems: 'flex-end' }}>
          <img style={{ objectFit: 'contain', width: '30vw' }} src={ErrorIcon} alt='Erro' />
        </div>
        <Title {...flex(0.2, { textAlign: 'center', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', color: 'var(--main_text)', fontSize: 30 })} title={title} />
        <Text {...flex(0.2, { textAlign: 'center', justifyContent: 'center', color: 'var(--main_text)', fontSize: 16 })} message={ENUM.MSG_TENTE_MAIS_TARDE} />

        <footer {...flex(0.2, { alignItems: 'flex-end' })}>
          <Button title={ENUM.BTN_TITLE_OK_ENTENDI} onClick={() => props.history.push(isLogout ? '' : 'inicio')} />
        </footer>
      </Main>
    </React.Fragment>
  )
}
