import React from 'react'
import './button.css'
import Loading from '../loading/Loading'
import { flex } from '../../../infra/flex'

export default function Button({ className = '', disabled, title, isLoading, danger, ...props }) {
  const computedClassName = ['', className, danger && 'bpp-danger', isLoading && 'bpp-button-loading'].join(' ')
  if (disabled) {
    return <button className={`bpp-button${computedClassName}`} disabled={disabled || isLoading} style={{ borderColor: 'var(--disabled_btn)', border: 'none', backgroundColor: 'var(--disabled_btn)' }} {...props}>
      {isLoading ? <div {...flex(1, { alignItems: 'center', justifyContent: 'center' })}>
        <Loading small />
      </div> : title}
    </button>
  }

  // if (className === 'bpp-button bpp-image telefone') {
  //   return <button className={`bpp-button${computedClassName}`} disabled={disabled || isLoading} {...props}>
  //     {isLoading ? <div {...flex(1, { alignItems: 'center', justifyContent: 'center' })}>
  //       <Loading small />
  //     </div> : title}
  //   </button>
  // }

  return <button className={`bpp-button${computedClassName}`} disabled={disabled || isLoading} {...props}>
    {isLoading ? <div {...flex(1, { alignItems: 'center', justifyContent: 'center' })}>
      <Loading small />
    </div> : title}
  </button>
}
