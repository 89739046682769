import { createReducer } from '@reduxjs/toolkit'
import { UPDATE_CARTAO_ID, UPDATE_CONTA_ID, UPDATE_ID_PESSOA, UPDATE_CONTA_PAI_ID, UPDATE_PORTADOR_ID } from './actions'
import { CLEAR_APP } from '../app/actions'

const initialState = {
  cartaoId: undefined,
  contaId: undefined,
  idPessoa: undefined,
  contaPaiId: undefined,
  portadorId: undefined
}

const reducer = createReducer(initialState, {
  [UPDATE_CARTAO_ID]: (state, action) => {
    state.cartaoId = action.payload
  },
  [UPDATE_CONTA_ID]: (state, action) => {
    state.contaId = action.payload
  },
  [UPDATE_ID_PESSOA]: (state, action) => {
    state.idPessoa = action.payload
  },
  [UPDATE_CONTA_PAI_ID]: (state, action) => {
    state.contaPaiId = action.payload
  },
  [UPDATE_PORTADOR_ID]: (state, action) => {
    state.portadorId = action.payload
  },
  [CLEAR_APP]: (state, action) => {
    return initialState
  }

})

export default reducer
