import React, { useState } from 'react'

import Navigation from '../../components/mobile/navigation/Navigation'
import Input from '../../components/mobile/input/Input'
import Main from '../../components/mobile/main/Main'
import Title from '../../components/mobile/title/Title'
import Button from '../../components/mobile/button/Button'
import Text from '../../components/mobile/text/Text'
import bppFetch from '../../infra/bpp-fetch'
import store from 'store2'
import { flex } from '../../infra/flex'
import ENUM from '../../infra/constants'
import { useSelector } from 'react-redux'

export default function RegisterPassword ({ ...props }) {
  const contaIdData = useSelector(state => state.global.contaId)

  let contaId = contaIdData
  const [pin, setPin] = useState('')
  const [pinConfirm, setPinConfirm] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [ativacao] = useState(true)

  function mySubmitFunction (e) {
    e.preventDefault()
    return false
  }

  function confirmHash (rel) {
    let telefoneTitular = props.location.state.telefoneTitular
    let options = {
      method: 'GET'
    }

    let urlConfirma = `/v1/operacao/${rel}/otp?canalToken=sms&transacao=aprovacao`

    bppFetch(urlConfirma, options, props.isAppConnected)
      .then((response) => {
        if (!response.error) {
          props.history.push({ pathname: '/confirmar-telefone', state: { ativacao, telefoneTitular, pin, novoPin: true } })
        } else {
          setErrorMsg(ENUM.ERRO_TOKEN_INCORRETO)
        }
      })
      .catch(() => setErrorMsg(ENUM.ERRO_EFETUAR_OPERACAO))
  }

  function register () {
    if (pin !== pinConfirm) {
      setErrorMsg(ENUM.ERRO_SENHA_DIFERENTE)
    } else if (pin === '' || pinConfirm === '') {
      setErrorMsg(ENUM.CAMPOS_OBRIGATORIOS)
    } else {
      setLoading(true)

      let options = {
        method: 'POST',
        body: JSON.stringify({
          operacao: ENUM.OPERACAO_ALTERAR_PIN_KEY,
          valor: 0,
          contaDestinoId: contaId,
          dadosIntegracao: {
            pin: pin
          }
        })
      }

      bppFetch('/v1/operacao', options, props.isAppConnected)
        .then(({ error, data, response, request }) => {
          if (error || data.erros) {
            setErrorMsg(data.erros[0].mensagem)
          } else {
            var rel = data.rel
            store.session('relHash', rel)
            confirmHash(rel)
          }
        })
        .catch(() => setErrorMsg(ENUM.ERRO_EFETUAR_OPERACAO))
    }
  }

  function ChangePasswordFilter (pinValue) {
    const re = /^[0-9\b]+$/
    if (pinValue === '' || re.test(pinValue)) {
      setPin(pinValue)
    }
  }

  function ChangePasswordConfirmFilter (pinValue) {
    const re = /^[0-9\b]+$/
    if (pinValue === '' || re.test(pinValue)) {
      setPinConfirm(pinValue)
    }
  }

  function onKeyInputPassword (e) {
    var mEvent = e
    var mPressed = mEvent.keyCode
    if (mPressed === 13) {
      document.getElementById('passwordInputConfirm').focus()
    }
    return true
  }

  function onKeyConfirmPassword (e) {
    var mEvent = e
    var mPressed = mEvent.keyCode
    if (mPressed === 13) {
      register()
    }
    return true
  }

  return (
    <React.Fragment>
      <Navigation {...flex(0.1)} title={ENUM.TITLE_CADARTRA_PIN} handleBack={props.history.goBack} />
      <Main {...flex(0.9)}>
        <form onSubmit={(event) => mySubmitFunction(event)}>
          <Title {...flex(0.2)} title={ENUM.TITLE_CADASTRA_SENHA} />

          <Input {...flex(0.1, { marginTop: 33 })} onKeyUp={e => onKeyInputPassword(e)} placeholder={ENUM.PLACEHOLDER_CADASTRE_SENHA} type={'tel'} className='changePassword' maxLength='4' value={pin} onChange={e => ChangePasswordFilter(e.target.value)} />
          <Input {...flex(0.1, { marginTop: 33 })} onKeyUp={e => onKeyConfirmPassword(e)} id='passwordInputConfirm' placeholder={ENUM.PLACEHOLDER_CONFIRME_SENHA} type={'tel'} className='changePassword' maxLength='4' value={pinConfirm} onChange={e => ChangePasswordConfirmFilter(e.target.value)} />
          <Text {...flex(0.1)} danger message={errorMsg} />
        </form>
        <footer {...flex(0.4, { alignItems: 'flex-end' })}>
          <Button title={ENUM.TITLE_CADASTRA} isLoading={isLoading} onClick={register} />
        </footer>
      </Main>
    </React.Fragment>
  )
}
