import { createAction } from '@reduxjs/toolkit'
export const CLEAR_APP = 'app/CLEAR_APP'
export const SET_APP_UPDATED = 'app/SET_APP_UPDATED'
export const SET_APP_CONNECTED = 'app/SET_APP_CONNECTED'
export const SET_APP_DISCONNECTED_MODAL = 'app/SET_APP_DISCONNECTED_MODAL'

export const clearApp = createAction(CLEAR_APP)

export const setAppUpdated = createAction(SET_APP_UPDATED)

export const setAppConnected = createAction(SET_APP_CONNECTED)

export const setAppDisconnectedModal = createAction(SET_APP_DISCONNECTED_MODAL)
