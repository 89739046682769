import { combineReducers } from 'redux'
import app from './app/reducer'
import extract from './extract/reducer'
import account from './account/reducer'
import card from './card/reducer'
import global from './global/reducer'

export default combineReducers({
  app,
  extract,
  account,
  card,
  global
})
