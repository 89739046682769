import React from 'react'
import './mainDesk.css'

export default function MainDesk ({ noPadding, children, list, modal, ...props }) {
  if (noPadding) {
    return (
      <main
        className={'bpp-mainDesk bpp-mainDesk-list-no-padding'}
        {...props}>
        {children}
      </main>
    )
  }
  return (
    <main
      className={modal ? 'bpp-main bpp-mainDesk-modal' : list ? 'bpp-mainDesk bpp-mainDesk-list' : 'bpp-mainDesk'}
      {...props}>
      {children}
    </main>
  )
}
