import bppFetch from '../bpp-fetch'
const options = {
  method: 'GET'
}

export function carregaExtratoApi (contaId, contasPrestadas, dataInicio, dataFim, connection) {
  return bppFetch('/v1/conta/' + contaId + '/extrato?dataInicial=' + dataInicio + '&dataFinal=' + dataFim + '&ordem=DESC', options, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data.resultado
      }
    })
    .catch(() => {
      return 'error'
    })
}

export function carregaDadosPessoaisApi (idPessoa, connection) {
  return bppFetch('/v1/pessoa/' + idPessoa, options, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch(() => {
      return 'error'
    })
}

export function carregaDadosCartaoApi (cartaoId, connection) {
  return bppFetch('/v1/conta/cartao?cartaoId=' + cartaoId, options, connection)
    .then(({ error, data, response, request }) => {
      if (error || data.erros) {
        return 'error'
      } else {
        return data
      }
    })
    .catch(() => {
      return 'error'
    }
    )
}
