import React from 'react'
import './header.css'

export default function Header ({ topContent, content, hideContent, footerContent, ...props }) {
  return (
    <div className='bpp-header-container' {...props}>
      {topContent}
      {content}
      {hideContent}
      {footerContent}
    </div>
  )
}
