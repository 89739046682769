import React, { useState } from 'react'

import bppFetch from '../../infra/bpp-fetch'
import store from 'store2'
import { flex } from '../../infra/flex'
import ENUM from '../../infra/constants'
import { useSelector } from 'react-redux'
import NavigationDesk from '../../components/desktop/navigationDesk/NavigationDesk'
import MainDesk from '../../components/desktop/mainDesk/MainDesk'
import TitleDesk from '../../components/desktop/titleDesk/TitleDesk'
import InputDesk from '../../components/desktop/inputDesk/InputDesk'
import ButtonDesk from '../../components/desktop/buttonDesk/ButtonDesk'
import TextDesk from '../../components/desktop/textDesk/TextDesk'
import TopContent from '../../components/desktop/topContent/TopContent'
import HeaderDesk from '../../components/desktop/headerDesk/HeaderDesk'
import Card from '../../components/desktop/card/Card'

export default function RegisterPasswordDesktop ({ ...props }) {
  const contaIdData = useSelector(state => state.global.contaId)

  let contaId = contaIdData
  const [pin, setPin] = useState('')
  const [pinConfirm, setPinConfirm] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [ativacao] = useState(true)

  function mySubmitFunction (e) {
    e.preventDefault()
    return false
  }

  function confirmHash (rel) {
    let telefoneTitular = props.location.state.telefoneTitular
    let options = {
      method: 'GET'
    }

    let urlConfirma = `/v1/operacao/${rel}/otp?canalToken=sms&transacao=aprovacao`

    bppFetch(urlConfirma, options, props.isAppConnected)
      .then((response) => {
        if (!response.error) {
          props.history.push({ pathname: '/confirmar-telefone', state: { ativacao, telefoneTitular, pin, novoPin: true } })
        } else {
          setErrorMsg(ENUM.ERRO_TOKEN_INCORRETO)
        }
      })
      .catch(() => setErrorMsg(ENUM.ERRO_EFETUAR_OPERACAO))
  }

  function register () {
    if (pin !== pinConfirm) {
      setErrorMsg(ENUM.ERRO_SENHA_DIFERENTE)
    } else if (pin === '' || pinConfirm === '') {
      setErrorMsg(ENUM.CAMPOS_OBRIGATORIOS)
    } else {
      setLoading(true)

      let options = {
        method: 'POST',
        body: JSON.stringify({
          operacao: ENUM.OPERACAO_ALTERAR_PIN_KEY,
          valor: 0,
          contaDestinoId: contaId,
          dadosIntegracao: {
            pin: pin
          }
        })
      }

      bppFetch('/v1/operacao', options, props.isAppConnected)
        .then(({ error, data, response, request }) => {
          if (error || data.erros) {
            setErrorMsg(data.erros[0].mensagem)
          } else {
            var rel = data.rel
            store.session('relHash', rel)
            confirmHash(rel)
          }
        })
        .catch(() => setErrorMsg(ENUM.ERRO_EFETUAR_OPERACAO))
    }
  }

  function ChangePasswordFilter (pinValue) {
    const re = /^[0-9\b]+$/
    if (pinValue === '' || re.test(pinValue)) {
      setPin(pinValue)
    }
  }

  function ChangePasswordConfirmFilter (pinValue) {
    const re = /^[0-9\b]+$/
    if (pinValue === '' || re.test(pinValue)) {
      setPinConfirm(pinValue)
    }
  }

  function onKeyInputPassword (e) {
    var mEvent = e
    var mPressed = mEvent.keyCode
    if (mPressed === 13) {
      document.getElementById('passwordInputConfirm').focus()
    }
    return true
  }

  function onKeyConfirmPassword (e) {
    var mEvent = e
    var mPressed = mEvent.keyCode
    if (mPressed === 13) {
      register()
    }
    return true
  }

  const topContent = (
    <TopContent history={props.history} />
  )
  return (
    <React.Fragment>

      <HeaderDesk {...flex(0.1, { paddingLeft: '10vw', paddingRight: '10vw', justifyContent: 'center' })} topContent={topContent} />

      <MainDesk id='ChangePasswordDesk' {...flex(1, { background: 'var(--main_content_bg)', padding: '1vh 10vw 0px 10vw' })} >
        <NavigationDesk {...flex(0.2, { fontSize: 24, fontFamily: 'Uni Sans Bold', maxHeight: '11vh' })} title={ENUM.ALT_VOLTAR} isGoback history={props.history} />
        <Card {...flex(0.8, { alignSelf: 'center', maxHeight: '33vh', minHeight: '260px' })} >

          <form onSubmit={(event) => mySubmitFunction(event)}>
            <TitleDesk {...flex(0.3, { marginBottom: 30 })} title={ENUM.TITLE_CADASTRA_SENHA} />

            <InputDesk {...flex(0.1, { marginTop: 10 })} onKeyUp={e => onKeyInputPassword(e)} placeholder={ENUM.PLACEHOLDER_CADASTRE_SENHA} type={'tel'} className='changePassword' maxLength='4' value={pin} onChange={e => ChangePasswordFilter(e.target.value)} />
            <InputDesk {...flex(0.1, { marginTop: 10 })} onKeyUp={e => onKeyConfirmPassword(e)} id='passwordInputConfirm' placeholder={ENUM.PLACEHOLDER_CONFIRME_SENHA} type={'tel'} className='changePassword' maxLength='4' value={pinConfirm} onChange={e => ChangePasswordConfirmFilter(e.target.value)} />
            <TextDesk {...flex(0.1)} danger message={errorMsg} />
          </form>
          <footer {...flex(0.4, { alignItems: 'flex-end' })}>
            <ButtonDesk title={ENUM.TITLE_CADASTRA} isLoading={isLoading} onClick={register} />
          </footer>
        </Card>
      </MainDesk>
    </React.Fragment>
  )
}
